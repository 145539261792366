import React from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
import closeIcon from "../../assets/close.png";

const InfoModal = ({ isOpen, onClose, contents, title }) => {
  const text1 = (
    <>
      셀러어드민 개인정보 처리방침
      <br /> 주식회사 21세기전파상(이하 "회사"라 합니다)은 셀러어드민 사업자의
      개인정보를 처리함에 있어 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
      통신비밀보호법, 전기통신사업법, 개인정보보호법 등 정보통신서비스제공자가
      준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
      의거한 개인정보 처리방침을 수립·준수하여 셀러어드민 사업자 권익 보호에
      최선을 다하고 있습니다.
      <br /> 본 개인정보 처리방침에서 사용된 용어의 의미는 특별한 규정이 없는 한
      21세기전파상 판매채널 서비스 제공 이용약관(셀러어드민 사업자용)과
      동일합니다.
      <p>제 1 조 [개인정보의 처리목적]</p>
      회사는 다음 각 호의 목적으로 셀러어드민 사업자 또는 소속 담당자의
      개인정보를 수집 및 이용합니다. 처리하고 있는 개인정보는 다음의 목적 이외의
      용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는
      등 필요한 조치를 이행할 예정입니다. <br />
      <List>
        <li> 1. 셀러어드민 사업자 가입 및 관리</li>
        <li>
          2. 셀러어드민 사업자 회원제 서비스 이용에 따른 셀러어드민 사업자 식별,
          가입의사 확인, 서비스 이용계약의 체결, 셀러어드민 사업자자격
          유지ㆍ관리, 서비스 부정이용 방지, 각종 고지 및 통지 등 연락 목적
        </li>
        <li>
          3. 21세기전파상 판매채널 서비스의 제공 및 서비스 제공에 따른 요금 정산
        </li>
        <li>
          4. 21세기전파상 판매채널 입점, 서비스의 중단 또는 변경에 대한 판단,
          셀러어드민 사업자가 셀러어드민 게재한 상품정보의 검수,환불업무의 수행,
          요금 추심, 계약서 또는 청구서의 발송 등의 목적
        </li>
        <li>5. 고충 처리</li>
        <li>
          6. 셀러어드민 사업자의 신원 확인, 사고 조사, 민원사항 확인 및 처리,
          사실조사를 위한 연락ㆍ통지, 처리결과 통보 등의 목적
        </li>
        <li>7. 신규 서비스 개발 및 마케팅, 광고에의 활용</li>
        <li>
          8. 신규 서비스 개발, 통계학적 특성에 따른 서비스 제공 및 광고 게재,
          서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공,
          접속빈도 파악, 회원의 서비스 이용에 대한 통계
        </li>
      </List>
      <p>제 2 조 [처리하는 개인정보의 항목]</p>
      회사는 다음 각 호의 개인정보를 처리하고 있습니다.
      <List>
        <li>
          1. 21세기전파상 판매채널 서비스 제공 및 운영·관리 : (필수) ID, 이메일,
          비밀번호, 휴대폰번호, 관리자명, 반품지 주소, 법인명(단체명),
          사업자분류, 과세유형, 사업자등록증, 사업자등록번호, 통신판매업
          신고번호, 고객센터 번호, CI
        </li>
        <li>
          2. 서비스 이용 내역 정보 : (필수) IP주소, 쿠키, 서비스 이용기록,
          방문기록, 불량 이용기록
        </li>
        <li>3. 정산 정보: (필수) 세금계산서, 계좌번호, 예금주명, 은행명</li>
      </List>
      <p>제 3 조 [개인정보 수집방법]</p>
      <List>
        <li>
          ① 회원가입 및 서비스 이용 과정에서 셀러어드민 사업자가 입력한 정보는
          원칙적으로 사업적 목적으로 사용되는 정보에 해당하나, 일부 개인정보가
          포함될 수 있으므로 회사는 해당 정보를 수집합니다.
        </li>
        <li>
          ② 셀러어드민 사업자가 서비스 이용과정에서 웹 또는 앱페이지, 메일,
          팩스, 전화 등 다양한 경로를 통하여 회사에 상담·문의하는 경우,
          개인정보가 수집될 수 있습니다.
        </li>
        <li>
          ③ 셀러어드민 사업자의 21세기전파상 판매채널 서비스 이용과정에서
          자동으로 서비스 이용 내역에 관한 정보가 수집될 수 있습니다.
        </li>
      </List>
      <p>제 4 조 [개인정보 자동수집 장치의 설치·운영 및 거부]</p>
      <List>
        <li>
          ① 회사는 셀러어드민 사업자에게 특화된 맞춤서비스를 제공하기 위해서
          셀러어드민 사업자들의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'
          등을 운용합니다.
          <ul>
            <li>
              1. 쿠키의 의미 : 쿠키란 웹 또는 앱을 운영하는 데 이용되는 서버가
              셀러어드민 사업자의 브라우저에 보내는 아주 작은 텍스트 파일로서
              셀러어드민 사업자의 컴퓨터 하드디스크에 저장되기도 합니다.
            </li>
            <li>
              2. 쿠키의 사용 목적 : 회사는 셀러어드민 사업자의 21세기전파상
              판매채널 셀러어드민 로그인 및 사이트 접속 빈도, 방문 시간 분석,
              이벤트 참여 분석 등의 목적으로 쿠키를 사용합니다.
            </li>
            <li>
              3. 쿠키설정 거부 방법 : 셀러어드민 사업자는 쿠키 설치에 대한
              선택권을 가지고 있습니다. 따라서, 셀러어드민 사업자는 웹
              브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
              저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
              수도 있습니다. 셀러어드민 사업자는 사용하는 웹 브라우저의 옵션을
              선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
              거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 크롬의 경우 웹
              브라우저 오른쪽 상단의 더보기 &gt; 설정 &gt; '개인정보 및 보안'
              에서 쿠키 및 기타 사이트 데이터를 클릭 &gt; '타사 쿠키 차단' 등
              필요한 항목 체크, 엣지의 경우 웹 브라우저 오른쪽 상단의 더보기
              &gt; 설정 &gt; '쿠키 및 사이트 권한' &gt; '쿠키 및 사이트 데이터
              관리 및 삭제' &gt; ' 타사 쿠키 차단' 등 필요한 항목 체크를 통해
              쿠키 설정의 거부가 가능합니다.
            </li>
          </ul>
        </li>
        <li>
          ② 셀러어드민 사업자가 쿠키 설치를 거부하는 경우 로그인이 필요한 일부
          서비스 이용에 어려움이 있을 수 있습니다.
        </li>
      </List>
      <p>제 5 조 [개인정보 제3자 제공]</p>
      <List>
        <li>
          ① 회사는 셀러어드민 사업자의 개인정보를 명시한 범위 내에서만 처리하며,
          정보주체의 동의를 받거나 법령의 특별한 규정이 있는 경우와 재난,
          감염병, 급박한 생명, 신체 위험을 초래하는 사건사고, 급박한 재산 손실
          등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에
          개인정보를 제공할 수 있습니다.
        </li>
        <li>
          ② 2세기전파상 판매채널 서비스의 특성상 셀러어드민 사업자 정보가
          21세기전파상 판매채널 서비스 이용자에게 공개 또는 제공될 수 있습니다.
          다만, 해당 정보는 셀러어드민 사업자가 셀러어드민에 게시한 정보의
          범위에 한정되고, 회사는 별도로 공개되지 않은 정보를 제3자에게
          공개하거나 제공하지 않습니다.
        </li>
      </List>
      <p>제 6 조 [개인정보처리의 위탁]</p>
      <List>
        <li>
          ① 회사는 원활한 업무 처리를 위하여 다음과 같이 개인정보 처리 업무를
          위탁하고 있습니다.
        </li>
        <ul>
          <li>
            <strong>위탁업체</strong>
          </li>
          <li>
            <strong>위탁업무내용</strong>
          </li>
          <li>
            <strong>개인정보의 보유 및 이용 기간</strong>
          </li>
          <li>(주)케이넷</li>
          <li>전자세금계산서 발행 및 관리</li>
          <li>회원 탈퇴시 혹은 위탁계약 종료시까지</li>
          <li>
            단, 관계 법령의 규정에 따라 보존할 의무가 있으면 해당 기간 동안 보존
          </li>
        </ul>
        <li>
          ② 회사는 개인정보 처리 업무 위탁이 수반되는 계약의 체결시 위탁업무
          수행목적 외 의 개인정보 처리 금지, 기술적·관리적· 보호조치, 재위탁
          제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 문서로서
          명시하고, 수탁자가 개인정보를 안전하게 처리하는지 감독하고 있습니다.
        </li>
        <li>
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우 지체없이 본 개인정보
          처리방침을 통하여 공개합니다.
        </li>
      </List>
      <p>제7조 [개인정보 보유 및 이용기간]</p>
      <List>
        <li>
          ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보
          수집시 동의받은 개인정보 보유·이용 기간 내에서 개인정보를
          처리·보유합니다.
        </li>
        <li>② 회사의 개인정보 처리 및 보유기간은 다음과 같습니다.</li>
        <ul>
          <li>
            1. 셀러어드민 사업자의 회원가입 및 관리 : 서비스 이용계약
            종료시까지. 다만, 다음 사유에 해당하는 경우에는 해당 사유 종료시까지
            가. 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당
            수사·조사 종료시까지 나. 서비스이용계약에 따른 채권·채무관계가
            잔존하는 경우 정산시까지
          </li>
          <li>2. 셀러어드민 사업자의 서비스 이용 관련 자료</li>
          <ul>
            <li>
              <strong>관련법령</strong>
            </li>
            <li>
              <strong>보유 정보</strong>
            </li>
            <li>
              <strong>보유 항목</strong>
            </li>
            <li>
              <strong>보유 기간</strong>
            </li>
            <li>전자상거래 등에서의 소비자보호에 관한 법률</li>
            <li>표시ㆍ광고에 관한 기록</li>
            <li>광고 기록 등</li>
            <li>6개월</li>
            <li>
              전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              계약 또는 청약철회 등에 관한 기록
              <br />
              회원 식별정보, 계약 또는 청약철회 기록 등
            </li>
            <li>5년</li>
            <li>
              전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              대금결제 및 재화 등의 공급에 관한 기록
              <br />
              회원 식별정보, 계약 또는 청약철회 기록 등
            </li>
            <li>5년</li>
            <li>
              전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              소비자 불만 또는 분쟁처리에 관한 기록
              <br />
              회원 식별 정보, 분쟁처리 기록 등
            </li>
            <li>3년</li>
            <li>
              통신비밀보호법
              <br />
              통신사실확인자료
              <br />
              로그기록, IP 등
            </li>
            <li>3개월</li>
          </ul>
        </ul>
      </List>
      <p>제 8 조 [개인정보의 파기 절차 및 방법]</p>
      <List>
        <li>
          ① 원칙적으로 셀러어드민 사업자의 개인정보는 개인정보의 수집 및
          이용목적이 달성되면 지체 없이 파기됩니다.
        </li>
        <li>
          ② 정보주체로부터 동의를 받은 개인정보는 보유기간이 경과하거나 처리
          목적이 달성되었음에도 불구하고 다른 법령에 따라 보관하는 경우에는,
          해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여
          보존합니다.
        </li>
        <li>
          ③ 개인정보의 파기 절차 및 방법은 다음과 같습니다.
          <ul>
            <li>
              1. 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
              보호책임자의 승인을 받아 해당 개인정보를 파기합니다.
              <li>2. 회사는 다음 각 호의 방법으로 개인정보를 파기합니다.</li>
            </li>
          </ul>
        </li>
        <li>
          ○ 종이에 기록, 저장된 개인정보 : 분쇄기로 분쇄하거나 소각을 통하여
          파기
        </li>
        <li>
          ○ 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없도록
          로우레벨포맷(Low Level Format) 등 기술적 방법을 사용하여 삭제
        </li>
        <li></li>
      </List>
      <p>제 9 조 [정보주체의 권리·의무 및 행사방법]</p>
      <List>
        <li>
          ① 정보주체는 회사에 대하여 언제든지 다음 각 호의 개인정보 보호 관련
          권리를 행사할 수 있습니다.
          <ul>
            <li>1. 개인정보 열람 요구</li>
            <li>2. 오류 등이 있을 경우 정정 요구</li>
            <li>3. 삭제 요구</li>
            <li>4. 처리정지 요구</li>
          </ul>
        </li>
        <li>
          ② 제1항에 따른 권리행사는 회사에 대하여 서면, 전화, 전자우편,
          모사전송(FAX) 등을 통하여 할 수 있으며 회사는 이에 대하여 지체없이
          조치합니다.
        </li>
        <li>
          ③ 정보주체가 회사에 대하여 개인정보 오류 등에 대한 정정 또는 삭제를
          요구한 경우에는 회사의 정정 또는 삭제가 완료될 때까지 당해 개인정보를
          이용 또는 제공하지 않습니다.
        </li>
        <li>
          ④ 정보주체는 개인정보 보호법 등 관계 법령을 위반하여 회사가 처리하고
          있는 이용자 본인 또는 타인의 개인정보, 사생활을 침해하여서는 안
          됩니다.
        </li>
      </List>
      <p>제 10 조 [만 14세 미만 아동의 개인정보 처리]</p>
      <List>
        <li>
          ① 회사는 만 14세 미만 아동의 개인정보를 수집하지 않습니다. 다만,
          회사가 만 14세 미만 아동의 개인정보를 수집하는 상황이 발생하는
          경우에는, 법정대리인은 만 14세 미만 아동의 개인정보 수집·이용 또는
          제공에 대한 동의를 철회할 수 있으며, 해당 아동이 제공한 개인정보에
          대한 열람 또는 오류의 정정을 요구할 수 있습니다.
        </li>
        <li>
          ② 유선 또는 서면을 통하여 만 14세 미만 아동인 회원의 법정대리인이
          열람, 증명을 요구하는 경우, 회사는 대리관계를 증명하는 위임장 및
          인감증명서, 요청인 및 대리인의 신분증 사본 등의 증표를 제시 받아 해당
          요구를 하는 자가 진정한 법정대리인인지 여부를 확인합니다.
        </li>
      </List>
      <p>제 11 조 [개인정보의 안전성 확보조치]</p>
      회사는 개인정보의 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.
      <List>
        <li>1. 관리적 조치 : 내부관리계획의 수립 및 시행, 정기적 직원 교육</li>
        <li>
          2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 비밀번호의
          암호화, 보안프로그램의 설치 등
        </li>
        <li>3. 물리적 조치 : 자료보관실의 접근통제</li>
      </List>
      <p>제 12 조 [개인정보관리 책임자 및 담당자]</p>
      셀러어드민 사업자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
      관련 민원을 다음 각 호의 개인정보관리책임자 혹은 담당부서로 신고하실 수
      있습니다. 회사는 셀러어드민 사업자들의 신고사항에 대해 신속하게 충분한
      답변을 드릴 것입니다.
      <List>
        <li>
          ○ 개인정보 보호ㆍ관리 책임자
          <br />
          이 름 : 박영준
          <br />
          전 화 : 02-3453-7460
          <br />
          직 위 : 개인정보보호책임자
          <br />
          전자메일 : help@21market.kr
          <br />
          ○ 개인정보 보호 담당부서
          <br />
          부서명 : 개발팀
          <br />
          전 화 : 02-3453-7460
          <br />
          전자메일 : dev@21market.kr
        </li>
      </List>
      <p>제 13 조 [개인정보 열람 청구]</p>
      셀러어드민 사업자는 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
      회사는 셀러어드민 사업자의 개인정보 열람청구가 신속하게 처리되도록
      노력하겠습니다.
      <List>
        <li>
          부서명 : 개발팀
          <br />
          전 화 : 02-3453-7460
          <br />
          전자메일 : support@zigzag.kr
          <br />
        </li>
      </List>
      <p>제 14 조 [권익침해 구제 방법]</p>
      셀러어드민 사업자는 다음 각 호의 기관에 대해 개인정보 침해에 대한
      피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의
      기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지
      못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
      <List>
        <li>개인정보 침해신고센터 (한국인터넷진흥원 운영)</li>
        <li>
          소관업무: 개인정보 침해사실 신고, 상담 신청홈페이지:
          privacy.kisa.or.kr 전 화: (국번없이) 118 주 소: (58324) 전남 나주시
          진흥길 9(빛가람동 301-2) 3층 개인정보침해 신고센터
        </li>
        <li>개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</li>
        <li>
          소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결) 홈페이지:
          www.kopico.or.kr 전 화: 1833-6972주 소: 03171 서울특별시 종로구
          세종대로 209 정부서울청사 4층 개인정보 분쟁조정위원회
        </li>
        <li>대검찰청 사이버범죄수사단: 02-3480-3571 (https://spo.go.kr)</li>
        <li>경찰청 사이버수사국: 182 (ecrm.cyber.go.kr)</li>
      </List>
      <p>제 15 조 [개인정보처리방침의 적용 제외]</p>
      회사는 셀러어드민 사업자에게 홈페이지를 통하여 다른 웹사이트 또는 자료에
      대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대하여
      통제권이 없을 뿐만 아니라 이들이 개인정보를 수집하는 행위에 대하여 회사의
      '개인정보처리방침'이 적용되지 않습니다. 따라서, 회사가 포함하고 있는
      링크를 클릭하여 타 사이트의 페이지로 이동할 경우에는 새로 방문한 사이트의
      개인정보처리방침을 반드시 확인하시기 바랍니다.
      <br />
      <strong>부칙</strong>
      <br />이 약관은 2023년 02월 03일부터 적용됩니다.
    </>
  );
  const text2 = (
    <>
      셀러어드민 서비스 이용약관 (셀러용)
      <p>제 1 장 총칙</p>
      제1조 [목적]
      <List>
        <li>
          본 이용약관은 주식회사 21세기전파상(이하 "회사"라 함)이 셀러어드민을
          통하여 제공하는 전자상거래 관련 서비스 및 기타 서비스(이하 "서비스"라
          함)와 관련하여 “회사”와 셀러어드민의 회원(이하 "파트너"라 함) 간의
          권리, 의무, 기타 필요한 사항을 규정함으로써 상호 발전을 도모하는 것을
          그 목적으로 합니다.
        </li>
      </List>
      제2조 [용어의 정의]
      <List>
        <li>
          본 이용약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <ul>
            <li>
              1. "채널"은 파트너가 등록하는 상품 정보를 이용고객들에게 노출하고
              파트너와 서비스 이용고객 사이에서 거래가 이루어지는 가상 영업장의
              단위를 의미합니다. 이러한 채널은 크래커(kracker)등으로 구성되며,
              이는 회사의 정책에 따라 변경될 수 있습니다.
            </li>
            <li>
              2. “서비스”는 회사가 각 채널을 통해 파트너에게 제공하는 일련의
              서비스로서, 필수 서비스(입점 서비스 등)로 구분합니다.
            </li>
            <li>
              3. “셀러어드민"은 파트너가 상품을 판매할 수 있도록 회사가 제공하는
              거래 관리 페이지를 의미하며, 파트너는 셀러어드민을 통하여
              상품정보의 등록, 주문, 배송, 취소, 반품, 정산 등의 업무를 수행할
              수 있습니다.
            </li>
            <li>
              4. "상품정보"는 파트너가 직접 등록한 상품의 정보를 의미합니다.
            </li>
            <li>
              5. “이용고객”은 회사가 제공하는 채널을 통해 파트너가 판매하거나
              홍보하는 상품 등에 대한 정보를 획득하는 회원과 비회원을 의미하며
              구매자를 포함합니다.
            </li>
          </ul>
        </li>
      </List>
      제3조 [약관의 효력 및 변경]
      <List>
        <li>
          ① 본 이용약관은 회사가 파트너에게 가입완료 사실을 통지한 때로부터
          효력이 있습니다. 회사는 본 약관에서 규정하지 않은 세부적인 내용을
          이용정책으로 정하고 이를 셀러어드민에 고지할 수 있습니다.
        </li>
        <li>
          ② 회사는 합리적인 사유가 발생하는 경우 관련 법령에 위배되지 않는 범위
          안에서 본 이용약관을 개정할 수 있으며 약관을 개정할 경우 적용일자와
          변경사유를 밝혀 그 적용일자 7일 이전에 파트너센터를 통해 고지합니다.
          단, 파트너에게 불리한 내용으로 변경하는 경우에는 효력발생일로부터 최소
          30일 이전에 고지하고, 파트너에게 개별 통지합니다.
        </li>
        <li>
          ③ 본 조에 따른 이용약관의 개정에 동의하지 않는 파트너는 공지된 개정
          약관의 효력발생일 전날까지 회사에 이용약관 개정에 동의하지 않는다는
          의사표시를 함으로써 서비스 이용을 종료할 수 있으며, 회사가 고지하면서
          변경약관의 효력발생일까지 거부의사를 표시하지 아니하면 승인한 것으로
          본다는 뜻을 명확하게 안내하였음에도 파트너가 효력발생일까지 파트너가
          명시적으로 거부의사를 표명하지 않을 경우 개정된 약관에 동의한 것으로
          봅니다.
        </li>
      </List>
      <p>제 2 장 이용계약의 체결</p>
      제4조 [서비스 가입 신청 및 승낙]
      <List>
        <li>
          ① 서비스 이용계약은 회사가 제공하는 서비스를 이용하거나 채널에서
          판매를 하기 위해 본 이용약관에 동의하고 파트너 가입 신청을 한 자(이하
          ‘이용신청자'라 함)가 회사가 정한 절차에 따라 정보를 입력하여 파트너
          가입 또는 입점을 신청한 후, 회사가 심사를 거쳐 승낙함으로써
          성립합니다. 회사는 이용승낙의 의사표시를 서비스에 게시하거나, 전자우편
          또는 기타의 방법으로 할 수 있습니다.
        </li>
        <li>
          ② 이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 이를 신속히
          제출해야 하며, 회사는 해당 서류가 제출될 때까지 가입 신청에 대한
          승낙을 보류하거나 거부할 수 있습니다.
        </li>
        <li>
          ③ 이용신청자는 서비스 가입신청을 하기 전에 서비스의 내용, 거래 조건,
          기타 본 이용약관의 내용을 숙지하여야 하며, 이를 숙지하지 못해 생기는
          불이익에 대한 책임은 이용신청자에게 있습니다.
        </li>
        <li>
          ④ 회사는 이용신청자에게 다음 각 호의 사유가 발생하는 경우에는 승낙을
          거절 또는 유보할 수 있으며, 파트너 승낙 이후에도 사후적으로 아래 각
          호의 사유가 존재함을 알게 된 경우에는 파트너의 이용계약을 해지할 수
          있습니다.
        </li>
        <ul>
          <li>
            허위 또는 부정확한 정보를 기재하거나 항목의 누락 또는 오기가 있는
            경우
            <br />
            실명이 아니거나 타인의 명의 또는 타인의 사업자 정보를 이용한 경우
            <br />
            만 19세 미만의 미성년자인 경우로서 법정대리인의 영업활동에 관한 동의
            내용이 확인되지 않은 경우
            <br />
            상품의 판매 등에 필요한 허가, 신고 기타 필요한 자격을 갖추지 못한
            경우
            <br />
            본 이용약관 및 회사의 다른 서비스와 관련하여 이용 제한 등 이용자격을
            상실한 적이 있는 경우
            <br />
            사회의 안녕ㆍ질서 또는 미풍양속을 저해하거나, 저해할 목적으로 서비스
            가입 신청을 한 경우
            <br />
            회사의 신용, 이미지 또는 명예를 훼손하였거나 훼손할 우려가 있는 경우
            <br />
            회사의 업무상, 기술상의 사정으로 서비스 제공이 곤란한 경우
            <br />
            휴업, 폐업 또는 채무자 회생 및 파산에 관한 법률에 따른 파산 또는
            회생 절차의 개시 신청을 하는 등 정상적인 영업활동이 곤란한 경우
            <br />
            파트너의 귀책사유로 승낙할 수 없거나 기타 회사가 승낙을 거부할
            합리적 사유가 있는 경우
          </li>
        </ul>
      </List>
      <p>제 3장 일반사항</p>
      제6조 [회사의 의무]
      <List>
        <li>
          ① 회사는 파트너와의 이용계약에 따라 파트너에게 입점 서비스와 결제
          서비스 등을 제공합니다.
        </li>
        <li>
          ② 회사는 파트너에 대한 서비스 제공과 관련하여 관련 법령 및 본 이용약관
          상의 의무를 준수하고, 미풍양속에 반하는 행위를 하지 않으며, 계속적이고
          안정적인 서비스 제공을 위하여 최선을 다합니다.
        </li>
        <li>
          ③ 회사는 파트너가 안전하게 서비스를 이용할 수 있도록 파트너의 개인정보
          및 영업정보를 보호하기 위하여 최선을 다하며, 개인정보처리방침을
          공개하고 준수합니다.
        </li>
        <li>
          ④ 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및
          통신비밀보호법 기타 관련 법령을 준수합니다.
        </li>
        <li>
          ⑤ 회사는 서비스 이용과 관련하여 파트너가 정당한 의견 또는 불만을
          제기하는 경우, 이를 처리하고 셀러어드민 또는 전자우편 등을 통하여
          파트너에게 그 처리과정 및 결과를 전달합니다.
        </li>
      </List>
      제7조 [파트너의 의무]
      <List>
        <li>
          ① 파트너는 회사가 제공하는 입점 서비스 및 결제 서비스 등을 본
          이용약관에 따라 허용된 범위 내에서 이용하며, 서비스 이용과 관련하여
          다음 각 호의 행위를 하여서는 안됩니다.
        </li>
        <ul>
          <li>
            서비스 가입신청 또는 정보 변경 시 허위의 내용을 등록하는 행위
            <br />
            타인의 정보, 아이디와 비밀번호 등 서비스 이용 정보, 금융정보 등을
            도용하는 행위
            <br />
            타인(회사의 직원 등을 포함합니다)을 가장하거나 사칭하여 또는 타인의
            명의를 도용하여 서비스를 이용하거나 전자우편을 발송하는 행위
            <br />
            회사의 사전 승낙 없이 회사가 제공한 정보를 파트너 개인적 목적으로
            복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위
            <br />
            회사 및 기타 제3자의 저작권, 상표권 및 기타 지식재산권 등 타인의
            권리를 침해하는 행위
            <br />
            회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            <br />
            허위의 상품을 게재하는 등의 방법으로 이용고객에게 손해를 가하거나
            이용고객으로부터 불만ᆞ항의를 받을 수 있는 행위
            <br />
            외설ᆞ폭력ᆞ수치심ᆞ혐오감 등을 유발하거나 기타 공서양속에 반하는
            메시지ᆞ이미지 등의 정보를 스토어에 게재하는 행위
            <br />
            서비스 정보통신망에 장애를 유발시킬 수 있는 내용의 정보, 문자, 도형
            등을 채널에 게재하여 회사가 관리·운영하는 정보통신망에 피해를 주는
            행위
            <br />
            비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위
            <br />
            다른 스토어 사업자의 영업행위를 방해하는 행위
            <br />
            서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수
            있는 정보를 전송하는 행위
            <br />
            기타 법령 또는 본 이용약관에 위배되거나 회사의 업무를 방해하는
            부당한 행위
            <br />
          </li>
        </ul>
        <li>
          ② 파트너는 서비스의 이용과 관련하여 「전자상거래 등에서의 소비자
          보호에 관한 법률」, 「전자금융거래법」, 「여신전문금융업법」,
          「전기통신사업법」, 「화장품법」, 「전기용품 및 생활용품 안전관리법」,
          「부가가치세법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
          「상표법」, 「저작권법」, 「어린이제품 안전 특별법」 등 관계법령과
          이용약관, 세부이용지침, 서비스 이용안내 및 회사가 통지한 공지사항 등을
          준수하여야 하며, 이를 수시로 확인하여야 합니다.
        </li>
        <li>
          ③ 파트너는 부당하게 서비스, 광고 또는 광고매체에 위해가 되는 제3자의
          행위를 인지하는 경우 즉시 해당 사실을 회사에 알리고 만약 해당
          제3자와의 거래 관계가 있는 경우에는 이를 즉시 중단하여야 합니다.
        </li>
        <li>
          ④ 파트너는 서비스 이용과 관련하여 다음 각호의 사항을 준수하여야
          합니다.
          <br />
          <ul>
            <li>
              파트너는 자신의 상품정보를 신의에 따라 성실하게 관리해야 할 의무가
              있으며, 입점 서비스를 통해 회사의 채널 상에 노출되는 파트너의 모든
              상품정보 등이 관련 법령과 본 약관에 위반되지 않도록 관리 운영해야
              합니다. 파트너는 채널을 통한 판매조건(판매가격, 재고관리 등을
              말합니다)이 파트너의 자사몰을 포함한 기타 판매 채널과 비교하여
              열위에 놓이지 않도록 합리적인 노력을 기울여야 하고, 공정경쟁과
              관련한 회사의 정책을 준수하여야 합니다. 즉, 채널을 통해
              이용고객에게 제공되는 판매조건은 파트너가 기타 판매채널을 통해
              제공하는 것과 적어도 같거나 더 나은 수준이어야 합니다. 파트너는
              회사가 셀러어드민 및 개별 공지를 통해 내부 정책에 따라 노출을
              금지하는 상품정보 등을 회사의 채널에 노출하지 않아야 하며, 관련
              법령 및 회사의 정책을 준수하여야 합니다. 파트너는 에이전트(Agent),
              로봇(Robot), 스크립트(Script), 스파이더(Spider),
              스파이웨어(Spyware) 등의 자동화된 수단을 사용하여 입점 서비스를
              포함한 회사의 어떠한 서비스에도 접속하여서는 아니됩니다. 파트너는
              부당하게 회사의 서비스를 방해하는 제3자의 행위를 인지하고서도 해당
              제3자와의 거래를 계속 하여서는 아니됩니다. 파트너는 어떠한
              경우에도 방법을 불문하고 직접 또는 제3자를 활용하여 회사의
              서비스에 큰 부하를 야기하거나, 기타 회사 또는 회사가 운영하는
              서비스의 정상적인 운영을 방해하는 행위를 하거나 그러한 시도를
              하여서는 아니됩니다.
            </li>
          </ul>
        </li>
        <li>
          ⑤ 파트너는 자신의 귀책사유로 인해 회사와 제3자 간의 분쟁이 발생하는
          경우, 즉시 회사를 면책하고 파트너의 비용과 책임으로 해당 분쟁을
          해결해야 합니다.
        </li>
      </List>
      제8조 [파트너의 아이디 및 비밀번호 관리에 대한 의무]
      <List>
        <li>
          ① 파트너의 아이디와 비밀번호에 관한 관리책임은 파트너 자신에게 있으며,
          파트너는 자신의 아이디 및 비밀번호를 제3자가 이용하도록 하여서는
          안됩니다.
        </li>
        <li>
          ② 파트너는 제3자가 자신의 아이디 또는 비밀번호 등 개인정보를 이용하고
          있음을 인지한 경우 즉시 비밀번호 등을 변경하고, 회사에 이러한 사실을
          통지하며 이후 회사의 안내에 따라야 합니다.
        </li>
        <li>
          ③ 제2항의 경우에 해당 파트너가 회사에게 제3자의 개인정보 이용 사실
          등을 통지하지 않거나, 회사의 안내에 따르지 않음에 따라 발생한 불이익에
          대하여 회사는 어떠한 책임도 부담하지 않습니다.
        </li>
      </List>
      제9조 [서비스의 내용]
      <List>
        <li>① 회사는 파트너에게 아래와 같은 서비스를 제공합니다.</li>
        <ul>
          <li>
            입점 서비스 : 파트너가 셀러어드민에 등록한 상품 및 상품정보를 채널에
            노출시키고, 그를 통하여 이용고객들이 이를 이용한 내역(주문내역 등)
            정보를 제공합니다. 입점 서비스를 이용하기 위해서는 회사가 정하는
            절차에 따라 입점 신청이 이루어져야 합니다. 기타 서비스 : 향후
            파트너의 개선요청 또는 회사의 발전적인 마케팅 정책에 따라 추가적인
            서비스가 제공될 수 있습니다.
          </li>
        </ul>
        <li>
          ② 회사는 파트너의 상품 등을 채널에 노출하고 이용고객과의 거래를 중개할
          뿐, 파트너의 상품에 대한 거래는 파트너와 이용고객 사이에 이루어집니다.
        </li>
        <li>
          ③ 회사는 기술적으로 지원이 어려운 경우 등의 사유가 있는 경우에는 본
          조에 따른 서비스의 제공을 거절하거나, 중단 또는 정지할 수 있습니다.
          (마케팅 정책이 추가되면서 효력이 발생할 수 있는 조항)
        </li>
        <li>
          ④ 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 365일
          24시간 스토어 사업자에게 제1항의 서비스를 제공합니다. 다만 정기 점검
          등의 필요가 있는 경우 일정기간 동안 서비스의 제공이 제한될 수 있으며,
          또한 회사의 호스팅 서비스 업체 등 제휴사의 운영시간 등의 사유로 인하여
          서비스의 제공이 제한될 수 있습니다.
        </li>
      </List>
      제10조 [서비스의 변경 및 중단]
      <List>
        <li>
          ① 회사는 다음 각호의 경우 파트너에 대하여 제공하는 서비스의 전부 또는
          일부를 일시적으로 변경하거나 중단할 수 있습니다.
        </li>
        <ul>
          <li>
            이용계약이 계약기간의 만료, 해지 등의 사유로 인하여 종료되는 경우
            <br />
            천재지변 또는 이에 준하는 불가항력으로 인하여 회사 서비스를 제공할
            수 없는 경우
            <br />
            설비의 보수점검, 교체 등 공사로 인하여 부득이한 경우
            <br />
            파트너가 의도적으로 회사의 정상적 영업 및 서비스 제공 활동을
            방해하는 경우
            <br />
            정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
            이용에 지장이 있는 경우
            <br />
            파트너가 약관을 위반한 경우
            <br />
            파트너가 판매하는 상품에 대하여 3회 이상 이용고객의 클레임이
            접수되고 클레임 내용이 서비스를 중단할 중대한 사유에 해당한다고
            인정되는 경우
            <br />
            파트너가 채무자 회생 및 파산에 관한 법률에 따른 파산 또는 회생
            절차의 개시 신청을 하는 등 정상적인 영업 활동이 곤란하다고 판단되는
            경우
            <br />
            휴업 또는 폐업 등의 이유로 판매 운영이 정상적으로 이뤄지지 않는다고
            판단되는 경우
            <br />
            국가기관 또는 정부조직(방송통신위원회 등), 수사기관, 법원 등의 행정
            또는 사법적 처분 등에 따라 서비스의 제공을 제한 또는 중단하여야 하는
            경우
            <br />
            기타 회사의 운영상, 기술상 필요 등 상당한 이유가 있는 경우
          </li>
        </ul>
        <li>
          ② 제1항의 경우, 회사는 사전에 다음 각호의 사항을 셀러어드민을 통해
          고지하거나 전자우편 등 회사가 정한 절차에 따라 파트너에게 통지합니다.
          다만, 사전에 통지할 수 없는 부득이한 사유가 있는 경우 회사는 서비스의
          제공을 변경 또는 중단하고 나서 사후적으로 파트너에 대한 통지를 할 수
          있습니다.
        </li>
        <li>
          서비스 변경 또는 중단 사유
          <br />
          서비스 변경일 또는 중단일
        </li>
      </List>
      제11조 [이용계약의 계약기간]
      <List>
        <li>
          ① 이용계약의 계약기간은 제4조 1항에 따른 이용계약 체결 시점부터 해당
          연도 말일까지이며, 파트너가 일방 당사자의 계약기간 만료 30일 전까지
          상대방에게 서면(전자우편 등 전자적 형태로 작성되거나 송신ㆍ수신 또는
          저장된 전자문서를 포함하며, 이하 같습니다)으로 계약 종료 의사를 밝히지
          않는 한, 이용계약은 동일한 조건으로 1년간 자동 연장됩니다.
        </li>
        <li>
          ② 계약 기간에 대하여 회사와 파트너 간 별도 합의가 있는 경우, 전항에
          기재된 내용이 아닌 별도 합의된 내용이 적용됩니다.
        </li>
      </List>
      제12조 [이용계약의 해지]
      <List>
        <li>
          ① 회사는 파트너에게 다음 각호의 사유가 존재하는 경우 상당한 기간을
          정하여 시정을 요구할 수 있고 그 기간 내에 해당 사유가 해소되지 않는
          경우 이용계약의 전부 또는 일부를 해지할 수 있습니다.
        </li>
        <ul>
          <li>
            파트너가 본 이용약관 또는 회사의 정책에 따른 의무를 이행하지
            아니하거나 위반하는 경우
            <br />
            사후적으로 파트너에게 제4조 제4항의 승낙 거부 사유가 있음이 확인된
            경우
            <br />
            파트너가 서비스 이용과정에서 회사의 임직원이나 이용고객 등에게 욕설,
            비방, 폭언, 협박, 음란한 언행 등을 한 경우
            <br />
            파트너의 고의∙과실 또는 반복되는 불성실한 업무수행으로 인하여
            이용계약을 유지할 수 없을 정도로 당사자 상호 간의 신뢰가 손상되는
            경우
            <br />
            기타 위 각호의 경우에 준하여, 회사가 이용계약을 유지하기 어려운
            중대한 사유가 발생한 경우
          </li>
        </ul>
        <li>
          ② 회사가 서비스의 전부 또는 일부를 제공하지 않기로 경영적 판단을 하는
          경우에는 파트너에 대한 30일 전 서면 통지로써 이용계약의 전부 또는
          일부를 해지할 수 있습니다.
        </li>
        <li>
          ③ 파트너는 서비스 이용의 종료를 원하는 경우 30일 전 서면 통지로써
          언제든지 이용계약을 해지할 수 있습니다.
        </li>
        <li>
          ④ 이용계약을 해지하고자 하는 당사자는 상대방에게 서면(전자우편
          포함)으로 해지사유를 밝혀 해지의사를 통지하여야 합니다.
        </li>
        <li>
          ⑤ 이용계약이 해지되는 경우에도, 해지 이전에 완결된 행위 관련해서는 본
          이용약관이 적용됩니다.
        </li>
        <li>
          ⑥ 본조에 의하여 이용계약이 해지되는 경우 해당 파트너에 대한 서비스
          제공이 종료되며, 회사는 서비스 종료와 관련한 제반 조치를 취할 수
          있습니다.
        </li>
        <li>
          ⑦ 제1항의 규정에도 불구하고, 객관적으로 시정할 수 없다고 판단하거나
          관련 법령을 위반하는 등 즉시 해지해야 할 긴급한 사정이 존재 경우,
          회사는 별도의 시정기간을 부여하지 않고 즉시 이용계약을 해지할 수
          있습니다.
        </li>
        <li>
          ⑧ 본조에 의한 이용계약의 해지의 효력은 제3항에 따른 해지의 의사표시가
          상대방에게 도달한 때부터 발생하며, 본조의 해지는 손해배상의 청구에
          영향을 미치지 않습니다.
        </li>
        <li>
          ⑨ 본조 제2항 내지 제3항에 따른 해지의 경우, 해지권자는 상대방에 대하여
          어떠한 손해배상 의무도 부담하지 아니합니다.
        </li>
      </List>
      <p>제 4장 입점 서비스</p>
      제13조 [입점 서비스의 내용]
      <List>
        <li>
          ① 회사는 제4조에 따라 이용계약을 체결한 파트너에 대하여 입점 서비스를
          제공하고, 파트너는 회사가 제공하는 입점 서비스를 이용할 수 있습니다.
        </li>
        <li>
          ② 회사가 제공하는 입점 서비스의 내용은 다음 각호와 같습니다.
          <ul>
            <li>
              셀러어드민에 직접 등록한 상품 및 상품 정보를 채널에 노출시키는
              서비스
              <br />
              이용고객들이 채널을 통하여 상품 등을 이용한 내역(주문내역 등)에
              대한 정보를 파트너에게 제공하는 서비스
            </li>
          </ul>
        </li>
        <li>
          ③ 회사는 별도의 입점 절차를 마련하여 운영할 수 있으며, 파트너에게
          필요한 서류의 제출을 요청할 수 있습니다. 파트너는 입점을 위하여 회사가
          정하는 입점 절차를 준수하여야 하고, 회사가 정하는 입점에 필요한 제반
          서류를 제출하여야 합니다.
        </li>
        <li>
          ④ 회사는 입점 서비스와 관련하여 내부 정책에 따라 물류대행서비스 및
          CS대행서비스를 제공할 수 있으며, 필요한 경우 제3자와의 업무제휴를
          통하여 이를 제공할 수 있습니다.
        </li>
      </List>
      제14조 [상품정보에 대한 책임]
      <List>
        <li>
          ① 파트너는 허위 또는 과장된 상품 정보를 게재해서는 아니 되며 정확한
          정보를 제공하여야 합니다. 이를 위반하여 분쟁이 발생하는 경우, 즉시
          회사를 면책하고 파트너가 모든 위험과 비용 등을 전적으로 책임지고
          해결해야 합니다.
        </li>
        <li>
          ② 채널에 노출되는 상품정보(가격, 재고) 등 모든 정보와 품질보증,
          하자보수 등은 파트너의 책임으로 철저히 관리 및 제공하여야 하며 변경
          사항이 발생한 경우에는 즉시 이를 갱신하여야 합니다.
        </li>
        <li>
          ④ 회사는 파트너의 상품정보가 다음 각호에 해당하는 경우, 사전 통보 없이
          채널 내에서 상품정보를 수정·삭제하거나 이용고객의 열람을 제한하는 등의
          조치를 취할 수 있습니다. 단, 제9호의 경우 회사는 7영업일 이전에
          파트너센터 또는 전자우편을 통하여 사전에 공지하여야 합니다.
          <ul>
            <li>
              허위의 상품 정보인 경우
              <br />
              파트너와의 이용계약이 해지된 경우
              <br />
              상품 구매자로부터 상품에 대한 항의가 3회 이상 제기된 상품인 경우
              <br />
              서비스의 변경으로 인하여 부득이한 경우
              <br />
              기타 본 이용약관 또는 관련법령에 위반하는 경우
            </li>
          </ul>
        </li>
        <li>
          ⑤ 파트너는 제4항에 의한 회사의 조치가 부당하다고 판단될 경우 그 이유를
          명시하여 이의를 제기할 수 있습니다.
        </li>
      </List>
      제15조 [파트너 운영 정책]
      <List>
        <li>
          ① 이용고객이 파트너의 상품을 구매한 경우, 파트너는 이용고객이 구매한
          재화를 지정된 인수인과 장소에 정확히 배송할 책임이 있으며, 회사는
          회사의 귀책사유가 없는 한 상품의 배송과 관련한 책임을 지지 아니합니다.
        </li>
        <li>
          ② 파트너는 배송 지연 등으로 인하여 이용고객으로부터 불만 사항을 접수한
          경우 이를 즉시 수리하여 조치하여야 합니다.
        </li>
        <li>
          ③ 파트너는 재고 부족, 교통 두절, 사고 발생 등 배송에 문제가 발생한
          경우 즉시 이용고객에게 통지하여야 합니다.
        </li>
        <li>
          ④ 파트너는 이용고객이 환불, 교환, 반품 또는 구매 취소를 요청하는 경우
          전자상거래법, 할부거래에 관한 법률 등 관련 법령을 준수하여 처리합니다.
        </li>
        <li>
          ⑤ 파트너는 상품을 판매함에 있어 관련 법령을 철저히 준수하여야 하며,
          법령에서 정한 사항을 반드시 이용고객에게 고지하여야 합니다.
        </li>
      </List>
      <p>제 5 장 결제 서비스</p>
      제16조 [결제 서비스 이용]
      <List>
        <li>
          ① 회사는 각 채널에서 파트너가 등록한 상품을 이용고객이 편리하게 결제할
          수 있도록 결제 서비스를 제공합니다. 결제 서비스는 회사가 제공하는 필수
          서비스이며, 파트너는 채널을 통해 상품을 판매할 경우 회사의 결제
          서비스를 이용할 의무가 있습니다.
        </li>
      </List>
      <p>제 6 장 정책일반</p>
      제17조 [결제 판매 수수료]
      <List>
        <li>
          ① 회사는 파트너에게 서비스 이용에 대한 수수료(이하 “수수료”)를
          부과하며, 수수료는 입점 서비스, 결제 서비스 및 각종 마케팅 서비스 등
          회사가 파트너에 제공하는 서비스에 대한 이용료를 의미합니다.{" "}
        </li>
        <li>
          ② 회사는수수료와 관련한 구체적인 사항 및 요율 등을 파트너센터 및
          회사가 정한 절차에 따라 별도 공지합니다. 단, 수수료에 관한 회사와
          파트너 간의 별도 합의가 있는 경우, 그 합의 내용이 우선합니다.
        </li>
        <li>
          ③ 회사는 결제대행업체를 통하여 파트너에게 지급될 정산대금에서수수료를
          우선 공제하는 방식으로 수수료를 징수합니다. 단, 회사는 내부 정책에
          따라 수수료의 징수 방식을 다르게 정할 수 있고, 부득이한 사정이 있는
          경우에는 회사와 파트너 간의 서면 합의로써 별도로 약정할 수 있습니다.
        </li>
        <li>
          ④ 회사는 내부 정책에 따라 수수료를 변경할 수 있으며, 변경사항은 본
          이용약관 제3조에서 규정하는 약관변경 절차에 준하여 파트너에게
          고지합니다.
        </li>
      </List>
      제18조 [판매촉진서비스]
      <List>
        <li>
          ① 회사는 상품판매의 촉진을 위하여 파트너가 구매회원에게 지급할 수 있는
          할인쿠폰, 포인트 제공, 리뷰 작성 이벤트 등 다양한 판매촉진서비스를
          유상으로 제공할 수 있습니다.
        </li>
        <li>
          ② 회사는 파트너에게 판매촉진서비스의 내용, 이용방법 및 이용요금,
          실시기간 등을 셀러어드민 또는 전자우편 등을 통해 고지함으로서 파트너의
          판매촉진 서비스 이용에 조력합니다.
        </li>
      </List>
      제19조 [파트너의 의무]
      <List>
        <li>
          ① 파트너는 본 이용약관과 회사가 채널을 통하여 미리 공지하는 개별약관이
          정하는 절차에 따라 판매정보의 등록, 판매진행과정의 관리, 구매회원에
          대한 거래이행, 물품배송, 청약철회 또는 반품, 교환 등의 사후처리를
          수행하여야 합니다.
        </li>
        <li>
          ② 파트너는 직거래를 유도하거나 이용고객의 정당한 권리(청약철회권 등)를
          제한해서는 아니됩니다.
        </li>
        <li>
          ③ 파트너는 판매 중이거나 판매를 개시할 상품의 재고를 항상 보유하여야
          하며 재고가 소진되어 정상적인 상품 배송에 차질이 예상될 경우, 지체없이
          상품의 판매를 중지하여야 합니다. 만약 이미 주문된 상품이 재고부족으로
          배송이 불가능할 경우, 구매자에게 반드시 양해를 구한 이후 파트너는 즉시
          해당 상품의 판매를 중지하고 그 사실을 회사에 통보하여야 합니다.
        </li>
        <li>
          ④ 파트너는 이용고객과의 거래와 관련하여 전자상거래법 등 관계법령에서
          정한 바에 따라 결제 및 판매 관련 자료를 보관하여야 합니다.
        </li>
        <li>
          ⑤ 파트너는 회사 및 잠재적 구매자를 포함하는 이용고객과의 원활한 연락을
          위해 항상 최신의 판매자 정보를 갱신하여야 할 의무가 있으며, 연락두절의
          예방을 위해 해외출장 및 여행 등의 경우 연락인을 별도로 강구하는 등의
          선량한 관리 노력을 기울여야 합니다.
        </li>
        <li>
          ⑥ 파트너는 다음 각호에 해당하는 행위를 하여서는 아니되며, 이를 위반한
          경우 회사는 서비스 이용정지 및 이용계약해지 등의 조치를 취할 수
          있습니다. 서비스 이용정지의 구체적인 기준 등에 대해서는 별도 고지를
          통해 안내합니다.
          <ul>
            <li>
              물품 또는 용역을 허위로 가장하거나 가족 또는 지인 등 타인의
              이용고객 ID를 이용하여 파트너가 판매 및 홍보하는 상품을 구매하는
              등의 부정거래 행위
              <br />
              회사의 결제 서비스를 통하지 않는 직거래 유도 행위 및 이용고객의
              직거래 요청을 수락하는 행위
              <br />
              허가 없이 타인의 상표나 로고를 사용하는 행위 등 타인의
              지식재산권을 침해하는 행위
              <br />
              연예인 사진 등 타인의 초상권 및 성명권을 침해하는 행위
              <br />
              회사가 제공하는 할인쿠폰, 마일리지 등을 부당하게 수취할 목적
              등으로 오프라인, 타 사이트 등에서 발생한 거래를 회사의 결제
              시스템을 이용하여 발생한 거래로 가장하여 거래하는 행위 또는 타
              사이트에서 발생한 거래 건을 결제 시스템을 이용하여 재주문하는 행위
              등<br />
              구매 관련 리뷰를 허위로 생성하거나 왜곡하는 행위
            </li>
          </ul>
        </li>
      </List>
      제20조 [취득한 개인정보의 보호]
      <List>
        <li>
          ① 파트너는 이용고객 등 타인의 개인정보를 본 이용약관에서 정한 목적
          이외의 용도로 사용하거나 제3자에게 제공하는 등 외부에 유출할 수
          없으며, 관련 법령 등에 따라 철저히 보호하여야 합니다.
        </li>
        <li>
          ② 회사는 배송 등의 목적으로 파트너에게 공개되어 있는 이용고객의
          개인정보를 회사의 정책에 따라 상당 기간이 경과한 후 비공개 조치할 수
          있습니다.
        </li>
        <li>
          ③ 파트너가 본조를 위반하여 이용고객 등과 분쟁이 발생하는 경우,
          파트너는 자신의 노력과 비용으로 회사를 면책시켜야 하며, 민ᆞ형사 상
          일체의 법적 책임을 부담하여야 합니다.
        </li>
      </List>
      제21조 [과세자료의 제공]
      <List>
        <li>
          ① 회사는 국세기본법, 부가가치세법 등 관련 법령 및 과세관청의 요청에
          따라 파트너의 과세자료(과세 기간 동안 채널에서 발생한 파트너의
          매출액과 관련하여, 회사가 과세관청에 제공하는 파트너의 신상정보 및
          매출자료 등을 의미합니다)를 과세관청에 제공할 수 있습니다.
        </li>
        <li>
          ② 회사는 관련 법령에 근거하여 과세관청에 정당하게 제공한 과세자료에
          의해 파트너에게 부과되는 세액 등에 대해서는 어떠한 책임도 지지
          않습니다.
        </li>
      </List>
      제22조 [셀러어드민의 운영관리]
      <List>
        <li>
          ① 파트너는 서비스를 통한 상품 판매 등이 제반 법령과 본 이용약관에
          위반되지 않도록 신의에 따라 성실하게 관리 및 운영할 책임이 있습니다.
        </li>
        <li>
          ② 파트너는 셀러어드민을 주문 조회, 배송 처리 등의 회사가 정한 목적에
          한하여 이용할 수 있으며, 셀러어드민을 통해 제공된 정보를 관련법령 및
          본 이용약관이 정한 것 이외의 목적으로 제3자에게 제공할 수 없습니다.
        </li>
        <li>
          ③ 파트너는 셀러어드민의 로그인 정보(아이디 및 비밀번호)를 관리할
          책임이 있으며, 회사는 파트너의 귀책사유에 따른 로그인 정보의 분실 및
          제3자 사용 등과 관련하여 발생하는 손해에 대해 책임지지 않습니다.
        </li>
        <li>
          ④ 파트너는 회사가 서비스 운영에 필요한 정보 및 증빙자료의 제공을
          요청할 경우 이에 성실히 응하여야 합니다.
        </li>
        <li>
          ⑤ 파트너는 이용고객으로부터의 세금계산서 등의 발급 요구에 성실히
          응하여야 합니다.
        </li>
        <li>
          ⑥ 파트너는 이용고객의 문의에 성실, 정확히 답변해야 하며, 파트너의
          부정확하거나 불성실한 답변으로 인하여 발생하는 모든 책임과 의무는
          파트너에게 있습니다. 파트너가 본 항에 위반하여 이용고객과의 분쟁이
          반복하여 발생하는 경우 회사는 파트너의 서비스 이용을 중단하거나 본
          이용약관에 따른 이용계약을 해지할 수 있습니다. 이때, 이용계약의 해지에
          대해서는 제12조를 준용합니다.
        </li>
        <li>
          ⑦ 이용고객이 구매평을 삭제하는 경우 관계법령에 따라 삭제된 게시글은
          작성일로부터 3년간 보관됩니다.
        </li>
        <li>
          ⑧ 파트너는 회사가 셀러어드민 등을 통해 게시 및 안내하는 서비스 운영
          정책 및 공지사항 등을 정확히 숙지하여야 하며, 이를 확인하지 않거나
          오인하여 발생하는 손해에 대해서 회사는 어떠한 책임도 부담하지
          않습니다.
        </li>
      </List>
      제23조 [상품의 배송]
      <List>
        <li>
          ① 이용고객의 주문 및 결제가 완료되면, 회사는 파트너가 주문 정보를
          확인할 수 있도록 조치를 취합니다. 파트너는 당해 주문 정보에 따라
          상품이 파손되지 않도록 적절한 포장을 한 후 배송의 증명이나 추적이
          가능한 물류대행(택배)업체에 배송을 위탁하여 상품을 배송하여야 합니다.
        </li>
        <li>
          ② 파트너는 직접전달 등 배송의 추적이 되지 않는 방법으로도 상품을
          발송할 수 있으나, 이로 인해 배송의 증명 또는 배송의 추적이 되지 않아
          이용고객으로부터 클레임이 발생할 경우, 상품배송에 관한 증빙자료를
          제시하여야 합니다.
        </li>
        <li>
          ③ 파트너는 이용고객의 결제일로부터 3 영업일 이내에 상품의 발송을
          완료하여야 하고(단, 파트너가 사전에 별도 배송조건을 설정한 상품의 경우
          그에 따름), 파트너센터에 송장번호 등의 발송 관련 정보를 입력하여
          발송이 완료되었음을 증명하여야 합니다.
        </li>
        <li>
          ④ 파트너가 제3항의 기한 내에 발송하지 못할 경우에는 고객에게
          배송지연사유 및 배송예정일을 안내해야 합니다. 만일, 파트너가 제3항의
          기한 내에 배송지연 안내를 하지 않거나, 배송지연 처리 시 안내한
          배송예정일까지 배송이 이루어지지 않는 경우 회사는 운영정책에 따라 해당
          파트너에게 페널티 부여 등의 불이익을 줄 수 있고, 해당 주문 건에 대해
          이용고객이 구매취소를 요청할 경우 해당 주문건은 파트너의 별도 승인
          없이 환불처리 됩니다. 단, 파트너가 사전에 별도 배송조건을 설정한
          상품의 경우는 달리 취급될 수 있습니다.
        </li>
        <li>
          ⑥ 이용고객의 귀책사유 없이, 파트너의 배송지 입력 오류 등으로 인하여
          이용고객이 상품을 정상적으로 수령하지 못한 경우 또는 상품의
          배송과정에서 하자가 발생한 경우, 파트너는 그에 관한 모든 책임을
          부담하여야 합니다.
        </li>
        <li>
          ⑦ 이용고객의 귀책사유 없이 상품의 배송이 지연되거나 미배송으로 거래가
          취소되는 경우 회사는 파트너에게 페널티 부여 등의 불이익을 줄 수
          있습니다.
        </li>
        <li>
          ⑧ 상품의 배송과 관련하여 이용고객과 분쟁이 발생한 경우 회사는
          파트너에게 배송완료의 증빙을 요청할 수 있으며, 파트너는 회사의 요청이
          있은 날로부터 7일 이내에 해당 자료를 제출하여야 합니다.
        </li>
        <li>
          ⑨ 파트너가 전항의 증빙자료를 제출하지 않거나, 허위 배송정보를
          입력함으로써 이용고객 또는 회사에 손해가 발생할 경우 그 손해 및 제반
          문제에 대한 모든 책임은 파트너에게 있으며, 회사는 파트너에게 페널티
          부여 등의 불이익을 줄 수 있습니다.
        </li>
      </List>
      제24조 [취소,반품,교환,환불]
      <List>
        <li>
          ① 파트너는 이용고객이 주문한 상품을 공급하기 곤란하다는 것을 알았을
          때, 즉시 해당 사실을 이용고객에 유선으로 통보하고 이용고객의 동의를
          얻은 후 주문을 취소하여야 하며, 회사가 이용고객의 상품결제일로부터 3일
          이내에 상품대금 환불 및 환불에 필요한 조치를 취할 수 있도록 협조하여야
          합니다.
        </li>
        <li>
          ② 파트너는 반품, 교환상품 수령일로부터 3영업일 이내에 반품, 교환 등을
          위한 조치를 취하여야 합니다. 만약 파트너가 7영업일 내에 아무런 조치를
          취하지 않을 경우, 해당 주문 건은 자동 환불될 수 있습니다.
        </li>
        <li>
          ④ 파트너는 상품 하자나 오배송 등 이용고객의 귀책사유 없는 반품 또는
          교환의 경우 이용고객에게 해당 배송비를 부담하도록 하여서는 안됩니다.
        </li>
        <li>
          ⑥ 이용고객은 상품 배송완료일로부터 7일 이내에 반품 및 교환 요청을 할
          수 있으며, 파트너는 이용고객이 동 기간 내 반품이나 교환을 요청하는
          경우 이용고객의 요청에 따라 반품 또는 교환을 해 주어야 합니다. 단,
          이용고객의 귀책사유로 상품이 훼손된 경우, 사용이나 일부 소비로 인해
          상품의 가치가 현저히 감소한 경우 등으로서 상품 판매 시 반품이나 교환의
          제한을 명시적으로 고지하고 이용고객의 동의를 받은 경우 및 기타 법령에
          의하여 반품 및 교환이 제한되어 있는 경우는 예외로 합니다. 또한, 상품이
          표시 또는 광고 내용과 다를 경우에는 상품수령 후 1년 이내 또는 그
          사실을 알게 된 날 또는 알 수 있었던 날로부터 1년 이내에 이용고객이
          반품 또는 교환을 요청하면, 파트너는 이를 반품 또는 교환을 해주어야
          합니다.
        </li>
        <li>
          ⑦ 파트너는 셀러어드민를 포함하여 이용고객의 문의 등록 및 전화 등을
          통한 반품 및 교환신청을 수시로 확인하여 필요한 조치를 취하여야 합니다.
          반품 및 교환신청을 확인하지 못한 것은 파트너의 과실이며, 파트너는 이를
          이유로 반품을 거부하여서는 아니 됩니다.
        </li>
        <li>
          ⑧ 파트너는 자체 배송한 상품의 경우 최초 배송 시에 구매회원에게
          청약철회 및 반품 및 교환절차를 전자상거래법 등 관련법규에 따라 정확히
          안내하여야 합니다.
        </li>
        <li>
          ⑩ 파트너는 주문건에 대한 취소 및 반품, 교환 처리시 정확한 사유를
          입력하여야 하며, 이와 관련된 클레임 발생 시 파트너에게 페널티 부여
          등의 불이익이 발생될 수 있습니다.
        </li>
        <li>
          ⑪ 이용고객이 상품 수령 후 교환이나 환불을 요청하는 경우 파트너는 관련
          법률에 의거하여 반품을 받은 후 교환이나 환불 또는 환불에 필요한 조치를
          하며, 추가로 발생하는 비용은 교환이나 환불의 책임이 있는 측에서
          부담합니다. 만약, 이용고객의 교환 또는 환불 요청 후 14일이
          경과하였음에도 불구하고 파트너가 해당 주문을 교환 또는 환불처리 하지
          않을 경우, 회사는 파트너에게 이용고객의 교환 또는 환불 요청일로부터
          30일이 경과하기 전까지 이용고객의 요청에 대해 적절한 조치를 취할 것을
          통지하고, 해당 기간이 지나도록 파트너가 정당한 사유 없이 아무런 조치를
          취하지 않거나 별도의 의사표시를 하지 않을 경우 회사가 해당 주문을
          취소하고 환불할 수 있습니다. 이때, 회사에 발생하는 환불금 및 부수비용
          등에 대해서는 파트너가 회사에 지체없이 지급하여야 합니다.
        </li>
        <li>
          ⑫ 이용고객이 결제 후 구매확정 전 취소 시 발생한 결제 수수료는 회사에서
          부담합니다.
        </li>
        <li>
          ⑭ 회사는 원칙적으로 구매확정이 이루어진 이후에 상품의 하자 등
          결제대금의 환불사유가 발생하는 경우에는 이에 개입하지 않습니다.
          구매확정이 이루어진 이후에 발생하는 파트너와 이용고객 간에 발생하는
          모든 분쟁(회사의 채널을 통한 거래가 아닌 직거래 주문건 포함)은 거래
          당사자인 파트너와 이용고객이 해결하여야 합니다.
        </li>
        <li>
          ⑮ 본조 제14항에도 불구하고, 구매확정이 이루어진 이후 파트너와 이용고객
          간에 발생한 분쟁이 원활히 조정되지 못할 경우 분쟁의 원활한 조정을
          위하여 예외적으로 회사가 당해 분쟁에 개입할 수 있습니다. 다음 각호의
          경우 파트너가 분쟁 해결 의사가 없는 것으로 간주하고 이용고객의 이익을
          위해 회사가 판매자의 동의 없이 직권으로 구매확정된 주문을 취소할 수
          있습니다.
          <ul>
            <li>
              구매확정 후 상품의 하자 등 명백하고 정당한 환불 사유로 인해
              이용고객이 파트너에게 주문취소를 요청한 날로부터 30일 이내에
              파트너가 주문 취소 처리를 하지 않은 경우
              <br />
              파트너의 폐업 등으로 인해 셀러어드민에서 주문 취소 처리가 불가한
              경우
            </li>
          </ul>
        </li>
        <li>
          ⑯ 본조 제15항에 따라 구매확정된 주문건에 대한 취소가 발생하는 경우
          회사는 이용고객에게 결제대금을 환불합니다. 이때, 환불에 따라 회사에
          소요된 비용은 파트너가 부담하고, 회사는 파트너에 대한 정산 금액에서
          해당 환불 금액을 공제할 수 있습니다.
        </li>
      </List>
      제25조 [페널티 및 자격 정지]
      <List>
        <li>
          ① 회사는 서비스를 통한 거래의 안전성과 신뢰성을 제고하기 위하여 본
          이용약관에서 정한 바에 따라 파트너의 자격을 정지하거나 서비스의
          이용제한 기타 필요한 조치를 취할 수 있습니다.
        </li>
        <li>
          ② 회사가 파트너의 자격을 정지하거나 서비스의 이용제한, 기타 필요한
          조치를 취할 경우 회사는 본 이용약관에 특별한 규정이 없는 한 사전에
          파트너에게 유선 또는 전자우편, 기타의 방법을 통하여 통보합니다. 단,
          파트너와의 연락이 되지않거나, 긴급을 요하는 등 기타 불가피한 사정이
          있는 경우에는 사후에 통보할 수 있습니다.
        </li>
        <li>
          ③ 회사는 파트너에게 회사가 미리 고지하는 일정기간의 판매실적과
          판매거부 내역, 배송, 반품, 교환, 민원 처리지연 등을 참작하여 우대 혜택
          또는 회원자격 정지 등의 페널티를 부여할 수 있습니다.
        </li>
        <li>
          ④ 회사는 파트너가 본 이용약관, 회사의 운영정책 및 관련법령을
          위반하거나, 타인의 권리를 침해하는 행위 또는 그러한 위법ᆞ부당한 행위가
          있는 것으로 의심될 만한 상당한 이유가 있는 경우 파트너의 회원자격을
          정지하거나 서비스의 이용을 제한할 수 있습니다.
        </li>
        <li>
          ⑤ 회사는 파트너가 페널티에 대한 상세 내용을 확인할 수 있도록
          운영정책을 통해 상세 내용을 안내하며, 파트너에 대한 자격정지 및 서비스
          이용제한의 구체적인 기준 등에 대해서는 페널티정책에서 정합니다.
        </li>
      </List>
      제26조 [판매대금의 정산 및 지급]
      <List>
        <li>
          ① 회사는 결제 서비스의 제공을 위하여 결제대행업체(PG)를 이용합니다.
          파트너가 결제 서비스를 이용하는 경우, 회사가 파트너를 대표하여
          결제대행업체와 계약을 체결하고, 결제대행업체를 통한 결제에 동의한
          것으로 간주합니다.
        </li>
        <li>
          ② 결제 서비스를 통해 판매된 상품이 구매확정 되는 경우, 회사는 회사의
          정산일정 및 정책에 따라 판매대금에 수수료, 판매촉진 서비스 비용, 기타
          발생한 금액을 공제한 나머지 금액(이하 “정산대금”이라 합니다)을
          파트너에게 지급합니다.
        </li>
        <li>
          ③ 정산대금의 수령방법은 세금계산서를 통한 계좌 입금 방식이며,
          입금계좌의 예금주는 파트너와 동일인일 것을 원칙으로 합니다. 파트너의
          탈퇴 이후에 발생한 정산대금은 파트너가 탈퇴하기 전 최종적으로 지정한
          입금계좌로 지급됩니다.
        </li>
        <li>
          ④ 배송완료일(또는 서비스 이행일)로부터 7일 이내에 이용고객으로부터
          교환, 반품, 환불 요청이 없는 경우 또는 파트너가 배송을 완료하였음에도
          이용고객이 반품, 교환, 환불 등의 의사표시 없이 구매확정을 지연하는
          경우 회사는 회사의 정책에 따라 자동구매확정 절차를 진행할 수 있습니다.
          다만, 이용고객의 이의 제기 등 민원 등이 접수되는 경우 자동구매확정이
          되지 않을 수 있습니다.
        </li>
        <li>
          ⑤ 파트너의 계좌번호 오류, 시스템 오류, 이용정지 등으로 인하여 정산대금
          지급에 실패하는 경우 회사는 신속히 정산대금 지급이 이루어질 수 있도록
          노력합니다. 이 경우 파트너는 적극적으로 문제해결에 협조해야 합니다.
        </li>
      </List>
      제27조 [정산의 보류]
      <List>
        <li>
          ① 회사는 파트너의 귀책사유로 인해 회사 및 이용고객에게 발생한 손해
          등의 제비용을 판매대금 정산 시 공제할 수 있습니다.
        </li>
        <li>
          ② 회사는 휴업 또는 폐업 등으로 셀러어드민 운영 상황이 정상적이지
          않다고 판단될 경우, 정산지급을 보류할 수 있습니다.
        </li>
        <li>
          ③ 파트너의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등
          법원의 결정이 있을 경우, 회사는 파트너와 채권자 간의 합의 또는 채무의
          변제 등으로 동 결정이 해제될 때까지 판매대금의 지급을 중지할 수
          있습니다.
        </li>
        <li>
          ④ 파트너가 본 이용약관의 규정을 위반하거나 위반한 것으로 의심될 만한
          타당한 정황이 있는 경우 회사는 거래에 대한 처리, 취소 등을 위해
          판매대금의 정산을 일정기간 보류할 수 있습니다.
        </li>
        <li>
          ⑤ 본조에 정한 것 외에도 법령의 규정에 의하거나 합리적인 사유가 있는
          경우에는 회사는 파트너에게 통지하고 판매대금의 전부 또는 일부에 대한
          정산을 일정 기간 유보하거나 회사가 파트너에게 가지는 채권과 상계할 수
          있습니다.
        </li>
        <li>
          ⑥ 지급 보류된 정산금액을 청구하고자 할 경우, 파트너는 지급보류 사유가
          해소되었음을 확인하기 위하여 회사가 요구하는 서류를 서면으로
          제출하여야 합니다.
        </li>
        <li>
          ⑦ 회사는 본조에 따른 정산보류시 정산지연에 따른 지체책임을 부담하지
          아니합니다.
        </li>
      </List>
      제28조 [제조물 책임]
      <List>
        <li>
          ① 파트너는 제조물책임법의 규정에 따라 자신이 판매한 상품의 결함으로
          인하여 발생하는 사람의 생명, 신체 또는 재산상의 손해에 대하여
          배상하여야 하며, 회사를 면책시켜야 합니다. 만약, 회사에 배상책임이
          발생할 경우에는 회사가 지출한 배상액과 관 비용을 회사에 대하여 배상할
          책임을 집니다.
        </li>
        <li>
          ② 파트너는 표시의무 등 제조물책임법상의 제조자의 책임을 준수하여
          제조물책임이 발생하지 않도록 최선의 노력을 다해야 합니다.
        </li>
        <li>
          ③ 파트너가 관련 법령에서 요구하는 안전표시나 각종의 인증표시 등이 없는
          상품을 판매함으로써 회사가 관계기관이나 제3자로부터 책임을 부담하는
          경우에는 회사에 대하여 그 손해를 배상하여야 합니다.
        </li>
        <li>
          ④ 본조에 따른 파트너의 의무는 개별 상품이 판매된 날을 기준으로 10년간
          존속합니다.
        </li>
      </List>
      제29조 [회사의 면책]
      <List>
        <li>
          ① 회사는 통신판매중개자로서 채널의 운영 및 관리 책임만을 부담하며,
          채널을 통하여 이루어진 재화 또는 용역의 거래와 관련하여 이용고객 또는
          파트너을 대리하지 않습니다. 파트너는 이용고객과의 거래에 관하여 전적인
          책임을 부담합니다.
        </li>
        <li>
          ② 회사는 파트너와 이용고객 사이의 거래와 관련하여 판매의사 또는
          구매의사의 존부 및 진정성, 등록물품의 품질, 완전성, 안정성, 적법성 및
          타인의 권리에 대한 비침해성, 이용고객 또는 파트너가 입력하는 정보 등
          일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당
          파트너가 전적으로 부담합니다.
        </li>
        <li>
          ③ 회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검,
          교체 또는 고장, 통신의 두절 등으로 인하여 일시적 또는 종국적으로
          서비스를 제공할 수 없는 경우, 서비스 제공에 관한 책임이 면제됩니다. 이
          경우 회사는 채널의 화면에 게시하거나 기타의 방법으로 파트너에게
          통지합니다.
        </li>
        <li>
          ④ 회사는 구매회원 또는 파트너의 귀책사유로 인한 서비스 이용의 장애에
          대하여 책임을 지지 않습니다.
        </li>
      </List>
      <p>제 7 장 지식재산권 및 개인정보보호</p>
      제30조 [지식재산권]
      <List>
        <li>
          ① 파트너가 채널에 게재한 상품 정보의 지식재산권은 해당 파트너에게
          있습니다.
        </li>
        <li>
          ② 회사는 전항에 따른 파트너의 상품정보 관련 지식재산권을 파트너 홍보,
          당해 상품에 관한 상품정보의 효과적인 전달 및 판매 촉진을 위한 목적으로
          활용할 수 있습니다. 또한, 회사는 필요한 범위 내에서 상품정보의 전부
          또는 일부를 수정, 복제, 편집, 전송, 배포 등의 행위를 할 수 있으며,
          파트너는 본 항에 따른 일시적 사용에 대한 무상 사용권을 회사에
          부여합니다.
        </li>
        <li>
          ③ 회사가 작성한 저작물, 기타 서비스의 제공과 관련하여 발생한 콘텐츠 등
          일체의 산출물에 대한 저작권 등 지식재산권은 회사에게 귀속됩니다.
          파트너는 회사의 서면 동의 없이 회사의 지식재산권을 이용해서는
          아니됩니다.
        </li>
        <li>
          ④ 파트너가 작성한 상품정보가 타인의 지식재산권을 침해하는 경우 그에
          대한 모든 책임은 파트너가 부담합니다.
        </li>
        <li>
          ⑤ 파트너가 타인의 지식재산권을 침해하는 것으로 확인되거나 분쟁이
          발생하는 경우, 회사는 해당 파트너에게 관련 자료의 요청 및 삭제 요청을
          할 수 있습니다.
        </li>
        <li>
          ⑥ 만약 파트너의 지식재산권 등 침해로 인하여 회사가 제3자로부터
          손해배상청구 등 이의 제기를 받은 경우 파트너는 스스로의 비용으로
          회사를 면책하고 방어하여야 하며, 관련 법령이 정하는 바에 따라 회사에
          발생한 손해를 배상하여야 합니다.
        </li>
      </List>
      <p>제 8 장 기타</p>
      제31조 [손해배상]
      <List>
        <li>
          당사자 일방이 본 계약상 의무를 위반함으로 인하여 상대방에게 손해가
          발생한 경우, 귀책사유 있는 당사자는 상대방이 입은 손해를 배상해야
          합니다.
        </li>
      </List>
      제32조 [양도의 제한]
      <List>
        <li>
          당사자는 본 이용약관 및 각종 서비스 이용계약에 따라 가지는 권리 및
          계약상 지위를 제3자에게 양도하거나 담보 등의 목적으로 제공할 수
          없습니다.
        </li>
      </List>
      제33조 [비밀유지]
      <List>
        <li>
          ① 당사자는 서비스를 이용하는 과정에서 알게 된 상대방의 정보(이용고객
          정보 등 서비스 이용과 관련하여 취득한 개인정보보호법상 개인정보,
          회사의 기술 및 사업정보, 생산 및 판매계획, 노하우 등 부정경쟁방지법상
          비밀로 관리되는 정보)를 회사의 서면 동의 없이 제3자에게 누설,
          공개하거나 본 약관 이외의 목적으로 사용하지 아니합니다.
        </li>
        <li>
          ② 비밀유지 의무는 이용계약이 해지되거나 서비스 제공이 중단된 이후에도
          유효합니다.
        </li>
      </List>
      제34조 [완전합의]
      <List>
        <li>
          ① 본 이용약관에 명시되지 않은 사항은 관련 법령의 규정과 일반 상관례에
          의합니다.
        </li>
        <li>
          ② 본 이용약관에 명시된 한 개 또는 수개의 조항이 법령에 따라 무효로
          되는 경우에도 본 이용약관에 명시된 나머지 조항의 효력은 여전히
          유효합니다.
        </li>
      </List>
      제35조 [분쟁의 해결]
      <List>
        <li>
          본 이용약관 및 이용계약은 대한민국법을 준거법으로 하며,당사자간의
          합의로 분쟁을 해결할 수 없는 경우 그 분쟁에 관한 소송은 피고 관할
          소재지 법원에 제기한다.
        </li>
      </List>
      <strong>부칙</strong>
      <br />이 약관은 2023년 02월 03일부터 적용됩니다.
    </>
  );
  const text3 = (
    <>
      <h4>개인정보 수집 및 이용 목적</h4>
      셀러어드민 사업자 식별, 가입의사 확인, 서비스 이용계약의 체결, 스토어
      사업자 자격 유지, 관리, 서비스 부정 이용 방지, 이용 고지 및 통지 연락
      목적, 문의 및 상담, 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에
      따른 서비스 제공, 이벤트 정보 및 참여 기회 제공, 접속빈도 파악, 회원의
      서비스이용에 대한 통계 구분
      <br />
      <h4>필수 수집하는 개인정보 항목</h4>
      ID, 이메일, 비밀번호, 사업자등록번호, 법인명(단체명), 대표자명, 통신판매업
      신고번호, 고객센터 번호, 은행명, 계좌번호, 예금주명, 반품지주소
      <br />* 세금계산서 정보는 입점 승인 이후 파트너센터 최초 접속 시 별도 수집
      <h4>개인정보의 보유 및 기간</h4>
      이용계약 종료시까지. 단, 관계법령에 따라 또는 회사 정책에 따른
      정보보유사유가 발생하여 보존할 필요가 있는 경우에는 필요한 기간 동안 해당
      판매자 정보를 보관합니다.
      <h4>
        고객님께서는 동의를 거부할 권리가 있으며, 필수 항목 동의 거부 시
        21세기전파상 셀러어드민 가입 및 서비스 이용이 불가합니다
      </h4>
    </>
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <InnerBox>
        <CategoryBox>
          <img src={closeIcon} alt="닫기" onClick={onClose} />
          <Title>{title}</Title>
        </CategoryBox>
        <Contents className="scroll">
          {contents == 0 ? text1 : contents == 1 ? text2 : text3}
        </Contents>
      </InnerBox>
    </Modal>
  );
};

export default InfoModal;

const InnerBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 450px;
  padding: 20px 26px 30px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  outline: none;
  .scroll::-webkit-scrollbar {
    width: 5px;
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
  .scroll::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const CategoryBox = styled.div`
  background: #fff;
  padding: 0 0 15px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid #000;
  img {
    cursor: pointer;
  }
  .active {
    color: #fff;
    background: #0082ff;
  }
`;

const Contents = styled.div`
  height: 50vh;
  margin-top: 15px;
  overflow-y: scroll;
  line-height: 1.2;
  /* font-weight: 300; */
  font-size: 14px;
  section {
    position: relative;
    overflow: hidden;
    img {
      display: block;
    }
  }
  p {
    font-weight: 500;
    margin: 16px 0;
  }
  h4 {
    /* font-weight: 400; */
    margin: 10px 0 5px;
  }
`;

const Title = styled.h1`
  text-align: center;
  width: 100%;
  font-size: 20px;
`;
const List = styled.ol`
  margin: 5px 0;
  ul {
    padding-left: 5px;
    margin: 5px 0;
  }
  li {
    padding-left: 5px;
    display: list-item;
    margin: 3px 0;
  }
`;
